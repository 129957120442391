<template>
	<div class="ac_host_more_overlay" @click="close()">
		<div class="ac_host_more">
			<div class="ac_host_more_title"> {{ tipTitle }} </div>
			<div class="ac_host_more_img">
				<div
					:class="{
						'single-image-container': items.length === 1,
						ac_host_more_img: items.length > 1,
					}"
				>
					<div
						class="image-container"
						v-for="(item, index) in items"
						:key="index"
					>
						<img :src="item.pic" class="item-image" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "",
	props: {
		tipTitle: "",
		items: [],
	},
	created() {},
	data() {
		return {};
	},
	methods: {
		close() {
			this.$emit("hiddenHostMore");
		},
	},
	watch: {},
};
</script>

<style scoped>
.ac_host_more_overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}
.ac_host_more {
	width: 380px;
	padding: 20px;
	border-radius: 20px;
	opacity: 1;
	background: #ffffff;
	align-items: center;
	text-align: center;
	align-content: center;
	display: ruby;
}
</style>
<style scoped>
.ac_host_more_img {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 40px 20px; /* 水平间距40px，垂直间距20px */
}
.single-image-container {
	display: flex;
	justify-content: center;
	align-items: center;
	/* height: 30vh; */
}

.image-container {
	width: calc(50% - 20px); /* 计算每个图像容器的宽度，减去间距 */
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	overflow: hidden; /* 隐藏超出容器的部分 */
}

.single-image-container .image-container {
	width: 100%; /* 当只有一个图片时，容器宽度自动适应内容 */
}

.item-image {
	max-width: 100%;
	max-height: 100%;
	object-fit: contain; /* 确保图片按比例缩放 */
}
</style>

<style scoped>
@media screen and (max-width: 900px) {
}
</style>

<style></style>
